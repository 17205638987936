body {
	margin: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
	/* background-color: #1f2938; */
	background-color: #2b313d;
}

h1,
h2,
h3,
h4 {
	font-family: 'Lato', sans-serif;
}

a {
	text-decoration: none;
	color: inherit;
	cursor: pointer;
}
